import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import GetStarted from './partials/Getstarted';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const Products = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-20 mb-20',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Our Products',
    paragraph: 'Use our <span className="text-color-primary">AI Core</span> to Automatically <span className="text-color-primary">Understand</span> the Publisher’s Content in-Page and provide several services'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="products">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Contextify
                    </h4>
                  <p className="mb-8 text-sm">
                    Extract Meaning from your Contents main Keywords and Entities - re Cookieless
                    </p>
                    <Button tag="a" color="primary" wideMobile href="#DemoContextify" className="reveal-from-bottom pa-bottom">
                        Live Demo
                    </Button>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Content2Video
                    </h4>
                  <p className="mb-8  text-sm">
                    Generates <span className="text-color-primary"> Meaningful Videos</span> that summarizes a page
                    </p>
                    <Button tag="a" color="primary" wideMobile href="#content2video" className="reveal-from-bottom pa-bottom">
                        Learn More
                    </Button>
                </div>
              </div>
            </div>

          {/*  <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Semi-Automated: You keep total control
                    </h4>
                  <p className="m-0 text-sm">
                    Once the Extraction and nalysis done, you can access to a UI to reorder, customize, amend the sequences of the video before generating for you a preview
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Deployment and Monetization
                    </h4>
                  <p className="m-0 text-sm">
                    We handle every aspect for you, just Plug it and start getting additonal revenue from your content
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-05.svg')}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Easy to Install
                    </h4>
                  <p className="m-0 text-sm">
                      You can choose where you want the video to appear in your content

                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06.svg')}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Transparency
                    </h4>
                  <p className="m-0 text-sm">
                    A dashboard is available for you to Monitor your New Revenue Source
                    </p>
                     <Button tag="a" color="dark" wideMobile href="#">
                        Learn More
                    </Button>
                </div>
              </div>
            </div>*/}

          </div>

          <GetStarted />

        </div>
      </div>
    </section>
  );
}

Products.propTypes = propTypes;
Products.defaultProps = defaultProps;

export default Products;