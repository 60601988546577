import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const aboutus = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'text-gray-700 body-font',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );


  const team = ['Redouane Belhamissi', 'Mahidine Mendjeli', 'Gaël Trebos'];
  const teamImgs = ['rb', 'mm', 'gt'];

  return (
    <>
    <section id="about"
      {...props}
      className={outerClasses}
    >
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h3 class="sm:text-3xl text-2xl font-extrabold title-font my-8 text-white">About us</h3>
      <div class="text-color-mid">
      <p>Clever-Content has been founded in 2020 by 3 experts in all things in Publishing, they worked for several online publishers for more than 10 years (ex. Yahoo, BestofMedia Group, Purch, Future PLC). </p>
      <p><b>The Problem to Solve:</b> Publishing is tough, a lot of competitors, It’s time consuming, and very costly to create a single content. Content have a Volatile Value, need to immediately monetize it before losing its freshness. </p>
      <p>That's why this <i>Triumvirate</i> decided to help out the publishers by proposing a way to monetize their content quickly and swiftly and get additional revenue generated from it. The main product "Video" is only the begining of the Story ...To be continued...</p>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
    {/*team.map((value, index) => {
        return (
                  <div class=" items-center text-center text-gray-400">
                    <Image
                      src={require('./../../assets/images/team/'+teamImgs[index]+'.jpg')}
                      alt="{value}"
                      width={200}
                      height={200} 
                      className="h-32 object-contain m-auto"/>
                    <div class="font-medium title-font mt-2 text-white text-lg">{value}</div>
                    <div class="font-medium title-font mb-2 text-color-mid text-lg">Co-Founder</div>
                    <div class="w-12 h-1 bg-primary rounded m-auto">
                    </div>
                </div>
        )
      })*/}
  </div>
  </div>
    </section>

</>
  );
}

aboutus.propTypes = propTypes;
aboutus.defaultProps = defaultProps;

export default aboutus;