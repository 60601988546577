import React from 'react';
// import sections
import FeaturesSplit from '../components/sections/FeaturesSplit';
import DemoContextify from '../components/sections/DemoContextify';


const contextify = () => {

  return (
    <> 
    hello    
      <DemoContextify topOuterDivider="true" />
     
      {/*<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />*/}
    </>
  );
}

export default contextify;