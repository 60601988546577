import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Particles from 'react-particles-js';
import Helmet from 'react-helmet';

const LayoutDefault = ({ children }) => { 
		const seoTitle = children.props.seoTitle;

		console.log(children.props.seoTitle);
return (
  <>
	<Helmet title={seoTitle} />
    <Header navPosition="right" className="reveal-from-bottom" />
    <Particles className="particles"
      width="100vw"
      height="50vh"
    params={{
	    "particles": {
	        "number": {
	            "value": window.innerWidth/10
	        },
	        "size": {
	            "value": 1
	        }
	    },
	    "interactivity": {
	        "events": {
	            "onhover": {
	                "enable": true,
	                "mode": "repulse"
	            }
	        }
	    },
	    "style": {
	    	"width":"100vw"
	    }
	}} style={{"zIndex":1}} />
    <main className="site-content illustration-section-01"  style={{"zIndex":2}}>
      {children}
    </main>
    <Footer />
  </>
)};

export default LayoutDefault;  