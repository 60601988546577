import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonGroup from '../../elements/ButtonGroup';
import Button from '../../elements/Button';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3'])
}

const defaultProps = {
  children: null,
  tag: 'h2'
}

const GetStarted = ({
  className,
  data,
  children,
  containerClass,
  tag,
  ...props
}) => {

  const classes = classNames(
    'section-header',
    className
  );

  const Component = tag;

  return (
    <>
          <div className="reveal-from-bottom center-content section-inner" data-reveal-delay="600">
            <ButtonGroup>
              <Button tag="a" color="dark" wideMobile href="https://console.clever-content.com/">
              Get started
              </Button>
              <Button tag="a" color="primary" wideMobile href="https://docs.google.com/forms/d/e/1FAIpQLSfF4CAtRZjpUrSEgXnEFwdq7D2MMzAsfCUQNxOmD2dB4I1i4A/viewform">
              Get In Touch
              </Button>
            </ButtonGroup>
          </div>
    </>
  );
}

GetStarted.propTypes = propTypes;
GetStarted.defaultProps = defaultProps;

export default GetStarted;