import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses} id="Mission"
    >
      <div className="container-sm">
        <div className={innerClasses}>

          <div className="hero-content">
            <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              What We Do 
            </h2>
                <div className="container-xs">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Provide <span className="text-color-primary">Additional Revenue Sources</span> for Small/Medium/Large Publishers by Helping them <span className="text-color-primary">Understand, Spread and Monetize better</span> their <span className="text-color-primary">Long tail of contents</span>
                </p>
                </div>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="1200">
                Try our tool to convert any of your content <span className="text-color-primary">instantly</span> into a <span className="text-color-primary">highly
                 customizable video</span> available for you to <span className="text-color-primary">monetize</span>
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="2600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://console.clever-content.com/">
                    Get started
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="https://docs.google.com/forms/d/e/1FAIpQLSfF4CAtRZjpUrSEgXnEFwdq7D2MMzAsfCUQNxOmD2dB4I1i4A/viewform">
                    Request a Demo
                    </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
           <div className="hero-content py-12 reveal-from-bottom" data-reveal-delay="3200" id="whatwedo">
            <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Our Mission 
            </h2>
            <div className="container-xs">
                <p className="m-0 mb-32" >
                We help Publishers generating more revenue with their own content, without spending a lot of time on production.
                </p>
            </div>  
        </div>

        </div>
        
       
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;