import React, { useState } from 'react';
import axios from 'axios';
import validator from 'validator'
import ReactJson from 'react-json-view'

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';
import SectionHeader from './partials/SectionHeader';



const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}




class DemoContextify extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url : '', errorMessage : '', result : null};

  };
  
 jsonify = (data) => {
  return (<><h3>Result</h3><ReactJson src={data} name="Extraction" theme="bright:inverted" displayDataTypes={false} style={{ padding: '30px', backgroundColor: '#fff', fontSize: '11px'}} collapsed={4} enableClipboard={false}/></>)
 } 
setErrorMessage = (message) => {
  this.setState({errorMessage : message});
}
    
  validate = (value, callback) => {
    
    if (validator.isURL(value)) {
       this.setErrorMessage('');
      callback();
    } else {
      this.setErrorMessage(this.state.url + ' Is Not a Valid URL')
    }
  }

  handleUrlChange = event => {
    this.setState({ url: event.target.value });
  }

  handleClick = () => {
            console.log("here", this.state.url);

    if(this.state.url  !== ''){
      this.validate(this.state.url, () => {
        axios.get('https://api.clever-content.com/demo/retrotags',
        { params: { url: this.state.url, limit: 1 }},)
        .then(res => {
          if (res.data && res.data.status ==='success'){
              this.setState({result : this.jsonify(res.data.extraction.nlp)});          
          }
          if (res.data && res.data.status ==='progress'){
              this.setErrorMessage('Extraction of '+ this.state.url + ' is in progress...');
              var self = this;
              setTimeout(function(){ self.handleClick()}, 35000);     
          }
        }).catch((response) => { console.log('error', response); this.setErrorMessage(JSON.stringify(response.error + " " +response.message ));});

      }
      )

    }
 
  }
  render = (props) => {



  return (
    <section
      {...props}
      className="container"
    >
    <div className="section center-content-mobile reveal-from-top has-top-divider " id="DemoContextify">
         <SectionHeader containerClass="none" data={{
    title: 'Contextify',
    paragraph: "<h4>How to extract Meaningful Information from your Content</h4>Semantics allow us to offer unique opportunity to offer more context around the content read. <br/>Our API helps you rapidly build state-of-the-art language processing technology into your application, specially handy for cookieless solutions. <br/> We provide Automatic Topic Tagging, Keywords detection and Context extraction and Classification. We support <b>English</b> and <b>French</b>.<br/>Our Contextify endpoint offers a simple and combined response that allows you to extract from your live url entities mentioned in the text."
  }} className="container " />

    </div>

    

      <div className=" cta section section-header center-content-mobile reveal-from-bottom">
        <div className="cta-inner section-inner "
        >
          <div className="cta-slogan ">
          
            <h3 className="m-0" >
              Demo Contextify
              </h3>
              <h4>Try Out a URL to see what we can provide as Context for the page</h4>
          </div>
          <div className="form-group cta-action cursor-pointer">
            <Input placeholder="URL" onChange={this.handleUrlChange} wrapperClasse ="container"/>
            <Button tag="button" color="primary" wideMobile onClick={this.handleClick}>
                  Contextify!
            </Button>
           
          
        </div>
      </div>
       {(this.state.errorMessage !== '') ? (<div className="container section section-inner">
<span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{this.state.errorMessage}</span></div>) : (null)}
                {this.state.result}
        </div>
    </section>
  );
}
}

DemoContextify.propTypes = propTypes;
DemoContextify.defaultProps = defaultProps;

export default DemoContextify;