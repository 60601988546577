import React from 'react';
// import sections
import Mission from '../components/sections/Mission';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Products from '../components/sections/Products';
//import FeaturesSplit from '../components/sections/FeaturesSplit';
//import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Aboutus from '../components/sections/Aboutus';
import DemoContextify from '../components/sections/DemoContextify';

const Home = () => {

  return (
    <>     
      <Mission />
      <Products invertColor hasBgColor="true" />
      <FeaturesTiles />

      <DemoContextify invertColor topOuterDivider="true" />

      {/*<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
       <Testimonial topDivider /> */}
      <Aboutus />
           <Cta split />

    </>
  );
}

export default Home;