import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import privacy from './views/privacy';
import contextify from './views/contextify';
import Home from './views/Home';

// Initialize GTM

const tagManagerArgs = {
    gtmId: 'GTM-W5FGM5Q',
    dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)
// Initialize Google Analytics
ReactGA.initialize("UA-143160977-1");

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};


const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    tagManagerArgs.page = page;
    TagManager.dataLayer(tagManagerArgs)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault}/>
          <AppRoute exact path="/contextify" component={contextify} layout={LayoutDefault}/>
          <AppRoute exact path="/privacy" component={privacy} layout={LayoutDefault}/>

        </Switch>
      )} />
  );
}

export default App;